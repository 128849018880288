<template>
  <modal id="modifyContactInfo" :title="$t('ContactInformation')" width="900px" :is-show="isShow" @close-modal="hide"
         v-if="isShow">
    <div class="modify-wrap">
      <add-order-contact-information :area-code-options="areaCodeOptions"
                                     :appellation-options="appellationOptions"
                                     :datas.sync="contactInformation"
                                     :emergency-contact.sync="emergencyContact"/>
      <div class="actions">
        <!--保存修改-->
        <button class="button button-primary" @click="onSubmit">{{$t('Save Changes')}}</button>
      </div>
    </div>
  </modal>
</template>
<script>
  import {required, requiredIf, email} from 'vuelidate/lib/validators';
  import Modal from '../../../../components/Modal';
  import AddOrderContactInformation from './AddOrderContactInformation';
  import {scrollToY} from '../../../../utils';
  import {saveOrderContactInfo} from '../../../../api/admin-order';

  export default {
    name: 'ModifyContactInfoModal',
    props: {
      areaCodeOptions: Array,
      appellationOptions: Array,
      contact: Object,
      emergency: Object
    },
    data() {
      return {
        isShow: false,
        orderId: '',
        contactInformation: {
          userType: 'ordinary',
          isCalled: '',
          englishSurname: '',
          englishName: '',
          chineseSurname: '',
          chineseName: '',
          phoneType: '',
          userPhone: '',
          userEmail: ''
        },
        emergencyContact: {
          isUrgent: false,
          userType: 'urgent',
          englishSurname: '',
          englishName: '',
          chineseSurname: '',
          chineseName: '',
          phoneType: '',
          userPhone: ''
        }
      };
    },
    validations: {
      contactInformation: {
        isCalled: {required},
        englishSurname: {required},
        englishName: {required},
        phoneType: {required},
        userPhone: {required},
        userEmail: {required, email}
      },
      emergencyContact: {
        englishSurname: {
          required: requiredIf((datas) => {
            return datas.isUrgent;
          })
        },
        englishName: {
          required: requiredIf((datas) => {
            return datas.isUrgent;
          })
        },
        phoneType: {
          required: requiredIf((datas) => {
            return datas.isUrgent;
          })
        },
        userPhone: {
          required: requiredIf((datas) => {
            return datas.isUrgent;
          })
        }
      }
    },
    components: {AddOrderContactInformation, Modal},
    created() {
      this.orderId = this.$route.query.id;
    },
    methods: {
      show() {
        this.contactInformation = {...this.contact};
        this.emergencyContact = {...this.emergency};
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      },
      onSubmit() {
        const data = [this.contactInformation];
        if (this.emergencyContact.isUrgent) {
          data.push(this.emergencyContact);
        }

        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$message.warning(this.$t('Please complete the information before submitting'));
          this.$nextTick(() => {
            const $error = document.querySelector('#modifyContactInfo').querySelector('.is-error');
            if ($error) {
              scrollToY(document.querySelector('#modifyContactInfo').querySelector('.modal-content'), $error.offsetTop - 80);
            }
          });
          return false;
        }

        const loading = this.$loading({text: 'Loading'});
        saveOrderContactInfo(this.orderId, data).then(res => {
          const emergencyContact = Object.assign({...this.emergencyContact}, {id: this.emergencyContact.isUrgent ? new Date().getTime() : ''});
          this.$emit('update:contact', {...this.contactInformation});
          this.$emit('update:emergency', emergencyContact);
          this.hide();
          loading.close();
          this.$message.success('修改成功！');
        }).catch(() => {
          loading.close();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .modify-wrap{
    padding:30px;text-align:left;
    .actions{margin-top:30px;text-align:center;}
  }
</style>
