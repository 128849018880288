<template>
  <Modal
    :title="$t(orderInfo ? 'RefundDetailsAndNewOrders' : 'Refund details')"
    width="960px"
    :is-show="isShow"
    v-if="isShow"
    @close-modal="hide"
  >
    <div class="refund-details-tabs" v-if="userObject === 'business'">
      <Tabs v-model="activeName">
        <!--退款詳情-->
        <TabPane :label="$t('Refund details')" name="ByWeek">
          <div class="refund-details">
            <refund-details :details="orderCancel" />
            <div class="h3">
              <div class="cl">
                <!--退款明細-->
                <div class="fl">{{ $t("RefundDetails") }}</div>
                <!--交易貨幣-->
                <div class="fr currency">
                  {{ $t("TradingCurrency") }}：{{
                    $t("Currencys")[orderCancel.billingType]
                  }}
                </div>
              </div>
            </div>
            <refund-table :details="orderCancelDetails" />
          </div>
        </TabPane>
        <!--新訂單-->
        <TabPane :label="$t('NewOrder')" name="ByDate" v-if="orderInfo">
          <new-order
            :order-info="orderInfo"
            :passenger-info="travelers"
            :contact-information="contactInformation"
            :emergency-contact="emergencyContact"
            :country-options="countryOptions"
            :certificate-type-options="certificateTypeOptions"
            :appellation-options="appellationOptions"
            :plus-cost="plusCost"
          />
        </TabPane>
      </Tabs>
      <slot></slot>
    </div>
    <div class="refund-details" v-else>
      <refund-details :details="orderCancel" />
      <div class="h3">
        <div class="cl">
          <div class="fl">{{ $t("RefundDetails") }}</div>
          <!--交易貨幣-->
          <div class="fr currency">
            {{ $t("TradingCurrency") }}：{{
              $t("Currencys")[orderCancel.billingType]
            }}
          </div>
        </div>
      </div>
      <refund-table :details="orderCancelDetails" />
    </div>
  </Modal>
</template>
<script>
import RefundDetails from '../../../../components/RefundDetails';
import RefundTable from '../../../../components/RefundTable';
import order from '../../../../mixins/order';
import { getRefundDetails, getNewOrderDetails } from '../../../../api/admin-order';
import NewOrder from '../../../../views/admin/cancel-order/components/NewOrder';

export default {
  name: 'RefundDetailsModal',
  mixins: [order],
  props: {
    userObject: String,
    countryOptions: Array,
    certificateTypeOptions: Array,
    appellationOptions: Array,
    orderId: String
  },
  data() {
    return {
      isShow: false,
      activeName: 'ByWeek',
      orderCancel: {},
      orderCancelDetails: [],
      orderInfo: null,
      travelers: [],
      contactInformation: {},
      emergencyContact: {},
      plusCost: []
    };
  },
  components: { NewOrder, RefundDetails, RefundTable },
  created() {
    this.getRefundDetails();
    this.getNewOrderDetails();
  },
  methods: {
    show() {
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    // 获取退款详情
    getRefundDetails() {
      getRefundDetails(this.orderId).then((res) => {
        const data = res.value || {};
        this.orderCancel = data.orderCancel || {};
        this.orderCancelDetails = data.orderCancelDetails || [];
      });
    },
    // 获取新订单
    getNewOrderDetails() {
      getNewOrderDetails(this.orderId).then((res) => {
        const data = res.value;
        if (data) {
          this.orderInfo = data.newOrder.order;
          this.travelers = data.newOrder.travelers;
          this.contactInformation = this.createContactInformation(
            data.newOrder.contacts
          );
          this.emergencyContact = this.createContactInformation(
            data.newOrder.contacts,
            'urgent'
          );
          this.plusCost = data.plusCost;
        }
      });
    }
  }
};
</script>
<style scoped lang="less">
.refund-details-tabs {
  padding: 0 30px 40px;
  text-align: left;
  .refund-details {
    padding: 0;
  }
  /deep/ .tab-nav {
    margin-top: 16px;
    text-align: left;
  }
}
.refund-details {
  padding: 0 30px 40px;
  text-align: left;
  .h3 {
    position: relative;
    margin: 40px 0 16px;
    padding-left: 16px;
    font-size: 24px;
    &:after {
      position: absolute;
      top: 50%;
      left: 0;
      width: 6px;
      height: 20px;
      margin-top: -10px;
      background-color: #ff6f61;
      content: "";
    }
  }
  .currency {
    margin-top: 20px;
    line-height: 1;
    font-size: 14px;
    font-weight: normal;
  }
}
</style>
