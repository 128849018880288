<template>
  <div class="change-group-inner">
    <div class="cl">
      <p class="fl m-r">{{$t('Order number')}}：001</p>
      <p class="fl">{{$t('ProductNumber')}}：123</p>
    </div>
    <div class="title">{{$t('Are you sure to cancel the entire order?')}}</div>
    <div class="actions">
      <button class="button button-primary" type="button" @click="onSubmit">{{$t('OK')}}</button>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ChangeGroupByAll',
    data() {
      return {};
    },
    created() {
    },
    methods: {
      onSubmit() {
        this.$router.push({name: 'AdminChangeGroup'});
      }
    }
  };
</script>
<style scoped lang="less">
  .change-group-inner{
    padding:30px 0 40px;text-align:left;
    .title{
      position:relative;margin-top:24px;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
    .m-r{margin-right:24px;}
  }
</style>
