<template>
  <div class="main">
    <div class="actions cl">
      <div class="fl">
        <!--訂單狀態-->
        <div class="title">{{$t('Order status')}}：{{$t('OrderStatus')[orderInfo.orderStatus]}}</div>
        <!--成團狀態-->
        <div>{{$t('Group status')}}：{{orderInfo.groupStatus}}</div>
        <!--交易狀態-->
        <div>{{$t('TradingStatus')}}：{{$t('TransationStatus['+orderInfo.transationStatus+']')}}</div>

      </div>
      <!--發出通知-->
      <button class="button button-primary m-l fr" type="button"
              @click="openModal('contactClients')">
        {{$t('Send message to client')}}
      </button>
      <!--系统收據-->
      <button class="button button-primary m-l fr" type="button" v-if="orderInfo.receiptUrl != null"
              @click="openInNewTab(orderInfo.receiptUrl)">
        {{$t('View Receipt')}}
      </button>
      <div class="fr" v-if="orderInfo.orderSource!=='artificial'&&orderInfo.orderStatus!=='CANCEL'">
        <template v-if="orderCancel">
          <!--取消及退款表-->
          <button class="button button-primary m-l" type="button" @click="$refs.submitModal.show()"
                  v-if="orderCancel.userObject==='business'">
            {{$t('CancellationAndRefundForm')}}
          </button>
          <!--退款詳情-->
          <button class="button m-l" type="button" @click="openModal('refundDetails')" v-else>
            {{$t('Refund details')}}
          </button>
<!--          &lt;!&ndash;確認取消&ndash;&gt;-->
<!--          <button class="button button-primary m-l" type="button" @click="confirmCancelOrder"-->
<!--                  v-if="orderInfo.orderStatus==='USER_CHECK_REFUND'">-->
<!--            {{$t('ConfirmCancel')}}-->
<!--          </button>-->
        </template>
        <template v-else-if="orderInfo.orderStatus==='MISSION'">
          <!--已调团-->
          <button class="button m-l" type="button" :disabled="true">{{$t('AdjustedTourGroups')}}</button>
        </template>
        <template v-else>
          <template v-if="orderInfo.orderStatus!=='FINISH'">
            <!--取消訂單-->
            <button class="button m-l" type="button" @click="cancelOrderDialog" v-if="orderInfo.orderSource !== 'mission'">{{$t('Cancel order')}}</button>
            <button class="button m-l" type="button" @click="$router.push({name: 'AdminChangeGroup',query:{id:orderId}})">
          {{$t('Group application')}}</button>

          </template>
          <!--調團申請-->

          <!--确认订单：报名成功待商户确认订单并发出收据-->
          <button class="button button-primary m-l" type="button"
                  @click="openModal('sendReceipt')"
                  v-if="orderInfo.orderStatus==='NOT_SIGN_UP_SUCCESS'">
            {{$t('Confirm order')}}
          </button>
          <!--查看收據-->
          <button class="button m-l" type="button" @click="openOrderReceipt" v-if="orderReceiptUrl">
            {{$t('ViewReceipt')}}
          </button>
          <!--發出成團通知-->
          <button class="button button-primary m-l" type="button"
                  @click="openModal('sendReminder')"
                  v-if="orderInfo.orderStatus==='NOT_GROUP'&&orderInfo.isGroup==='Y'">
            {{$t('Send a group notice')}}
          </button>
          <!--查看成團通知書-->
          <button class="button m-l" @click="openModal('sendReminder')" v-if="orderNotice">
            {{$t('ViewGroupNotice')}}
          </button>
        </template>
      </div>
      <div class="fr" v-if="orderInfo.orderSource==='artificial'&&orderInfo.orderStatus!=='CANCEL'">
        <template>
          <!--發出成團通知-->
          <button class="button button-primary m-l" type="button"
                  @click="openModal('sendReminder')"
                  v-if="orderInfo.orderStatus==='NOT_GROUP'&&orderInfo.isGroup==='Y'">
            {{$t('Send a group notice')}}
          </button>
          <!--查看成團通知書-->
          <button class="button m-l" @click="openModal('sendReminder')" v-if="orderNotice">
            {{$t('ViewGroupNotice')}}
          </button>
        </template>
      </div>
    </div>
    <div class="fl-group">
      <div class="hd cl">
        <!--聯繫客戶-->
<!--        <a class="fr btn" @click="openModal('contactClients')">{{$t('Contact customer')}}</a>-->
        {{$t('Order content')}}<!--訂單內容-->
      </div>
      <div class="order-info">
        <!--訂單編號-->
        <div class="p">{{$t('Order number')}}：{{orderInfo.orderNo}}</div>
        <!--產品編號-->
        <div class="p">{{$t('ProductNumber')}}：{{orderInfo.productNo}}</div>
        <!--產品名稱-->
        <div class="p">{{$t('ProductName')}}：{{orderInfo.productTitle}}</div>
        <!--行程路線-->
        <div class="p">{{$t('Itinerary route')}}：{{orderInfo.lineName}} - {{orderInfo.lineTitle}}</div>
        <!--旅行團出發日-->
        <div class="p">{{$t('TourDepartureDay')}}：{{orderInfo.priceDate}}</div>
        <!--人數-->
        <div class="p">{{$t('Number of people')}}：{{passengerInfo.length}}</div>
        <!--房間數目-->
        <div class="p" v-if="isDay===0">{{$t('NumberOfRooms')}}：{{orderInfo.roomsNumber}}</div>
        <!--訂單來源-->
        <div class="p">{{$t('Order Source')}}：{{$t('orderSource')[orderInfo.orderSource]}}</div>
      </div>
    </div>
    <!--旅客資料-->
    <div class="fl-group">
      <div class="hd cl">
        <div class="fr">
          <a class="btn" @click="openModal('modifyPassenger')">{{$t('Modify Information')}}</a>
        </div>
        {{$t('Traveler information')}}
      </div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th></th>
            <th>{{$t('TravellerType')}}</th><!--旅客類型-->
            <th>{{$t('Appellation')}}</th><!--稱謂-->
            <th>{{$t('English name')}}</th>
            <th>{{$t('Gender')}}</th>
            <th>{{$t('Certificate Type')}}</th>
            <th>{{$t('Certificate of Issue')}}</th>
            <th>{{$t('Certificate Number')}}</th>
            <th>{{$t('Certificate Validity Period')}}</th>
            <th>{{$t('DateOfBirth')}}</th>
            <th>{{$t('Mobile Number')}}</th>
            <th v-if="orderInfo.roomsNumber%1 === 0.5&&isDay===0">{{$t('FightPassenger')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) of passengerInfo" :key="item.id">
            <td class="nowrap">{{$t('Passenger')}}{{index+1}}</td>
            <td>{{(item.travelerType+item.isOccupyBed)|dictName('travelerType')}}</td>
            <td>{{item.isCalled|dictName(appellationOptions)}}</td>
            <td>{{item.englishSurname}}/{{item.englishName}}</td>
            <td>{{item.genderType|dictName('gender')}}</td>
            <td>{{item.paperworkType|dictName(certificateTypeOptions)}}</td>
            <td>{{item.issuingCountry|dictName(countryOptions)}}</td>
            <td>{{item.idNumber}}</td>
            <td>{{item.effectiveDate}}</td>
            <td>{{item.birthday}}</td>
            <td class="nowrap">({{item.phoneType}}) {{item.userPhone}}</td>
            <td v-if="orderInfo.roomsNumber%1 === 0.5&&isDay===0">{{item.isFight==='Y'?$t('SharedRoom'):''}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--聯絡人資料-->
    <div class="fl-group">
      <div class="hd cl">
        <a class="fr btn" @click="openModal('modifyContact')">{{$t('Modify Information')}}</a>
        {{$t('ContactInformation')}}
      </div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th>{{$t('Appellation')}}</th>
            <th>{{$t('Contact Name')}}({{$t('English')}})</th>
            <th>{{$t('Contact Name')}}({{$t('Chinese')}})</th>
            <th>{{$t('Contact Number')}}</th>
            <th>{{$t('Email')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{contactInformation.isCalled|dictName(appellationOptions)}}</td>
            <td>{{contactInformation.englishSurname}}/{{contactInformation.englishName}}</td>
            <td>{{contactInformation.chineseSurname}}{{contactInformation.chineseName}}</td>
            <td>({{contactInformation.phoneType}}) {{contactInformation.userPhone}}</td>
            <td>{{contactInformation.userEmail}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--紧急聯絡人資料-->
    <div class="fl-group" v-if="emergencyContact.id">
      <div class="hd cl">
        <a class="fr btn" @click="openModal('modifyContact')">{{$t('Modify Information')}}</a>
        {{$t('Emergency Contact')[0]}}
      </div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th>{{$t('Name')}}({{$t('English')}})</th>
            <th>{{$t('Name')}}({{$t('Chinese')}})</th>
            <th>{{$t('Mobile Number')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{emergencyContact.englishSurname}}/{{emergencyContact.englishName}}</td>
            <td>{{emergencyContact.chineseSurname}}{{emergencyContact.chineseName}}</td>
            <td>({{emergencyContact.phoneType}}) {{emergencyContact.userPhone}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--詳細收費-->
    <div class="fl-group">
      <div class="hd">{{$t('Detailed Charge')}}</div>
      <charge-details-table :order="orderInfo"/>
    </div><br>
    <!--收據-->
      <div class="fl-group" >
        <div class="hd">{{$t('Receipt')}}</div>
        <div class="bd">
          <!--收據抬頭-->
          <div class="form-item">
            <div class="label">{{$t('Receipt up')}}：{{memo.gainGround}}</div>
          </div>
        </div>
      </div><br>
    <!--其他需求-->
      <div class="fl-group" >
        <div class="hd">{{$t('More demand')}}</div>
        <div class="bd">
          <!--其他需求-->
          <div class="form-item">
            <div class="label">{{$t('Other demands')}}：{{ memo.otherDemands }}</div>
            <div ></div>
          </div>
        </div>
      </div><br>

    <send-receipt-modal :order-id="this.orderId" @reload="getOrderDetail" ref="sendReceipt"/>
    <send-reminder-modal :order-id="this.orderId"
                         :order-info="orderInfo"
                         :passenger-info="passengerInfo"
                         :contact-information="contactInformation"
                         :order-notice="orderNotice"
                         :isDay="isDay"
                         @reload="getOrderDetail"
                         ref="sendReminder"/>
    <change-group-modal ref="changeGroup"/>
    <contact-clients-modal :orderID="this.orderId" ref="contactClients"/>
    <!-- <refund-details-modal ref="refundDetails"/> -->
    <refund-details-modal :user-object="orderCancel?orderCancel.userObject:''"
                          :country-options="countryOptions"
                          :certificate-type-options="certificateTypeOptions"
                          :appellation-options="appellationOptions"
                          :order-id="this.orderId"
                          ref="refundDetails">
      <div style="margin-top:40px;text-align:center;" v-if="orderInfo.orderStatus==='BUSINESS_CHECK_REFUND'">
        <!--拒绝-->
        <button class="button m-r" @click="updateOrderStatus('REFUSE_REFUND')">
          {{$t('Refuse')}}
        </button>
        <!--同意-->
        <button class="button button-primary" @click="updateOrderStatus('REFUNDING')">
          {{$t('Agree')}}
        </button>
      </div>
    </refund-details-modal>

    <modify-passenger-information-modal :passengers.sync="passengerInfo"
                                        :appellation-options="appellationOptions"
                                        :area-code-options="areaCodeOptions"
                                        :country-options="countryOptions"
                                        :certificate-type-options="certificateTypeOptions"
                                        ref="modifyPassenger"/>
    <modify-contact-info-modal :area-code-options="areaCodeOptions"
                               :appellation-options="appellationOptions"
                               :contact.sync="contactInformation"
                               :emergency.sync="emergencyContact"
                               ref="modifyContact"/>
    <submit-modal ref="submitModal">
      <button class="button button-primary" :disabled="orderInfo.transationStatus===1 && orderInfo.orderStatus==='USER_CONFIRM_REFUND'" @click="toCancelOrderApplication">{{$t('Modify')}}</button>
    </submit-modal>
  </div>
</template>
<script>
  import Vue from 'vue';
  import Vuelidate from 'vuelidate';
  import ChargeDetailsTable from '../components/ChargeDetailsTable';
  import SendReceiptModal from './components/SendReceiptModal';
  import SendReminderModal from './components/SendReminderModal';
  import ChangeGroupModal from './components/ChangeGroupModal';
  import ContactClientsModal from './components/ContactClientsModal';
  import RefundDetailsModal from './components/RefundDetailsModal';
  import ModifyPassengerInformationModal from './components/ModifyPassengerInformationModal';
  import ModifyContactInfoModal from './components/ModifyContactInfoModal';
  import SubmitModal from '../cancel-order/components/SubmitModal';
  import order from '../../../mixins/order';
  import {getOrderDetail, updateOrderStatus, getOrderReceipt, getOrderNotice, adminGetProduct} from '../../../api/admin-order';
  import {getDictList} from '../../../api/admin-common';

  Vue.use(Vuelidate);
  export default {
    name: 'AdminOrderDetail',
    mixins: [order],
    data() {
      return {
        orderId: '',
        orderInfo: {},
        passengerInfo: [],
        contactInformation: {},
        emergencyContact: {},
        countryOptions: [],
        certificateTypeOptions: [],
        appellationOptions: [],
        areaCodeOptions: [],
        orderCancel: null,
        orderReceiptUrl: '',
        orderNotice: null,
        productId: '',
        isDay: 0,
        memo: {}
      };
    },
    components: {
      SubmitModal,
      ChargeDetailsTable,
      ModifyContactInfoModal,
      ModifyPassengerInformationModal,
      RefundDetailsModal,
      SendReminderModal,
      SendReceiptModal,
      ChangeGroupModal,
      ContactClientsModal
    },
    created() {
      this.orderId = this.$route.query.id;
      this.getOrderDetail();
      this.getDicts();
    },
    methods: {
      confirmOrderSuccess() {
        const h = this.$createElement;
        this.$msgbox({
          title: null,
          message: h('div', {style: 'padding:30px 0 20px'}, [
            h('div', {style: 'font-size:18px'}, '已確認訂單 '),
            h('div', null, '是否同時發出成團通知？')
          ]),
          center: true,
          showClose: false,
          showCancelButton: true,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          lockScroll: false,
          confirmButtonText: '發出成團通知',
          cancelButtonText: '稍後再說',
          beforeClose: (action, instance, done) => {
            // instance.confirmButtonLoading = true;
            // instance.confirmButtonText = '执行中...';
            done();
          }
        }).then(action => {
          this.openModal('sendReminder');
        }).catch(() => {
        });
      },
      // 打开弹窗(消取訂單、調團申請、确认订单、發出成團通知、联系客户)
      openModal(name) {
        this.$refs[name].show();
      },
      // 获取字典
      getDicts() {
        // 国家/证件签发地
        getDictList('country').then(res => {
          this.countryOptions = res.value || [];
        });

        // 证件类型
        getDictList('certificateType').then(res => {
          this.certificateTypeOptions = res.value || [];
        });

        // 获取称谓选项
        getDictList('appellative').then(res => {
          this.appellationOptions = res.value || [];
        });

        getDictList('phonePrefix').then(res => {
          this.areaCodeOptions = res.value;
        });
      },
      // 获取订单详情
      getOrderDetail() {
        const loading = this.$loading({text: 'Loading'});
        getOrderDetail(this.orderId).then(res => {
          let order = res.value.order || {};
          console.log(res.value);
          const contacts = res.value.contacts || [];
          this.memo = res.value.memo || {};
          this.productId = order.productId;
          this.orderInfo = this.formatOrder(order);
          console.log('orderInfo:', this.orderInfo);
          this.passengerInfo = res.value.travelers || [];
          this.contactInformation = this.createContactInformation(contacts);
          this.emergencyContact = this.createContactInformation(contacts, 'urgent');
          this.orderCancel = res.value.orderCancel;
          this.createPassengers();
          this.getOrderReceipt();
          this.getOrderNotice();
          this.adminGetProduct();
          loading.close();
        }).catch(() => {
          loading.close();
        });
      },
      adminGetProduct() {
        adminGetProduct(this.productId).then(res => {
          this.isDay = res.value.isDay;
        });
      },
      // 生成旅客信息表
      createPassengers() {
        const {adultNumber, childNumber, notChildNumber, babyNumber} = this.orderInfo;
        if (!this.passengerInfo.length) {
          this.passengerInfo = [
            ...this.createPassengerList(adultNumber, 'adult', 'Y'),
            ...this.createPassengerList(childNumber, 'child', 'Y'),
            ...this.createPassengerList(notChildNumber, 'child', 'N'),
            ...this.createPassengerList(babyNumber, 'baby', 'N')
          ];

          // 如果客戶訂購旅團時選擇房間數目有0.5时，第一位旅客默认拼房
          // if (roomsNumber % 1 === 0.5) {
          //   this.passengerInfo[0].isFight = 'Y';
          // }
        }
      },
      // 修改订单状态（确认取消订单）
      confirmCancelOrder() {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('Are you sure you want to cancel this order', ['']),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              updateOrderStatus({orderId: this.orderId, orderStatus: 'REFUNDING'}).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.orderInfo.orderStatus = 'REFUNDING';
                this.$message.success(this.$t('ConfirmSuccess'));
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      },
      // 取消订单对话框
      cancelOrderDialog() {
        this.$msgbox({
          title: this.$t('Cancel order'),
          message: this.$t('confirm.cancelOrder1'), // 你需要取消当前的订单吗？请填写
          confirmButtonText: this.$t('CancellationAndRefundForm'), // 取消及退款表
          confirmButtonClass: 'center',
          lockScroll: false
        }).then(() => {
          this.toCancelOrderApplication();
        }).catch(() => {
        });
      },
      // 跳转到填写取消及退款表页
      toCancelOrderApplication() {
        this.$router.push({name: 'AdminCancelOrderApplication', query: {id: this.orderId}});
      },
      // 获取订单收据
      getOrderReceipt() {
        if (['NOT_GROUP', 'YES_GROUP', 'FINISH'].includes(this.orderInfo.orderStatus)) {
          getOrderReceipt(this.orderId).then(res => {
            if (res.value) {
              this.orderReceiptUrl = res.value.fileUrl;
            }
          });
        }
      },
      // 新窗口打开查看订单收据
      openOrderReceipt() {
        window.open(this.orderReceiptUrl, '_blank');
      },
      openInNewTab(url) {
        window.open(url, '_blank', 'noreferrer');
      },
      // 获取订单成团通知
      getOrderNotice() {
        // 已成团和已完成的才能查看
        if (['YES_GROUP', 'FINISH'].includes(this.orderInfo.orderStatus)) {
          getOrderNotice(this.orderId).then(res => {
            if (res.value) {
              this.orderNotice = res.value.notice;
            }
          });
        }
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .main{
    .title{font-size:24px;font-weight:bold;}
    .actions{
      padding-bottom:20px;border-bottom:1px dashed #ff6f61;
      .button{
        min-width:80px;padding-left:8px;padding-right:8px;
        &.m-l{margin-left:8px;}
      }
    }
    /deep/ .table{margin-top:0;padding-bottom:0;overflow-x: auto;}
  }
  .fl-group{
    .hd{
      position:relative;margin:20px 0;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
    .btn{
      display:inline-block;margin-top:3px;margin-left:8px;padding:0 8px;line-height:28px;color:#1890ff;font-size:14px;border:1px solid #1890ff;border-radius:4px;
      &.btn-gray{color:#999;border-color:#ccc;}
    }
  }
  .order-info{
    padding:20px;background:#f4f4f4;border-radius:4px;
    .p{
      margin-bottom:16px;
      &:last-child{margin-bottom:0;}
    }
  }
  .fc1{color:#ff6f61}
</style>
