import { render, staticRenderFns } from "./ContactClientsModal.vue?vue&type=template&id=4bc68239&scoped=true"
import script from "./ContactClientsModal.vue?vue&type=script&lang=js"
export * from "./ContactClientsModal.vue?vue&type=script&lang=js"
import style0 from "./ContactClientsModal.vue?vue&type=style&index=0&id=4bc68239&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc68239",
  null
  
)

export default component.exports