<template>
  <modal :title="$t('Group application')" :is-show="isShow" v-if="isShow" @close-modal="hide">
    <div class="change-group">
      <tabs v-model="activeName">
        <tab-pane :label="$t('Full single transfer')" name="ChangeGroupByAll">
          <change-group-by-all/>
        </tab-pane>
        <tab-pane :label="$t('Individual transfer')" name="ChangeGroupByIndividual">
          <change-group-by-individual/>
        </tab-pane>
      </tabs>
    </div>
  </modal>
</template>
<script>
  import Modal from '../../../../components/Modal';
  import Tabs from '../../../../components/Tabs/Tabs';
  import TabPane from '../../../../components/Tabs/TabPane';
  import ChangeGroupByAll from './ChangeGroupByAll';
  import ChangeGroupByIndividual from './ChangeGroupByIndividual';

  export default {
    name: 'ChangeGroupModal',
    data() {
      return {
        isShow: false,
        activeName: 'ChangeGroupByAll'
      };
    },
    components: {Modal, Tabs, TabPane, ChangeGroupByAll, ChangeGroupByIndividual},
    created() {
    },
    methods: {
      show() {
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      }
    }
  };
</script>
<style scoped lang="less">
  .change-group{
    padding:0 30px;
    /deep/ .tab-nav{margin-top:16px;text-align:left;}
    /deep/ .actions{
      margin-top:40px;text-align:center;
    }
  }
</style>
