<template>
  <modal :title="$t('Contact customer')" :is-show="isShow" v-if="isShow" @close-modal="hide">
    <div class="contact-clients">
<!--      <div class="order-info cl">-->
<!--        <div class="item">{{$t('Order number')}}：001</div>-->
<!--        <div class="item">{{$t('ProductNumber')}}：123</div>-->
<!--        <div class="item">{{$t('Travel group name')}}：中國南京4天團</div>-->
<!--        <div class="item">{{$t('Travel group date')}}：2019/01/01至2019/01/01</div>-->
<!--      </div>-->
      <div class="m-t">
        <el-input type="textarea"
                  :placeholder="$t('Please enter the message content')"
                  v-model="content"
                  size="auto"
                  maxlength="1000">
        </el-input>
      </div>
<!--      <div class="upload-wrap m-t">-->
<!--        <el-upload drag-->
<!--                   action=""-->
<!--                   multiple>-->
<!--          <div class="ico">-->
<!--            <img class="img" src="../../../../assets/images/icon/upload-red-80.png" alt=""/>-->
<!--          </div>-->
<!--          <div class="upload-txt">{{$t('Click or drag and drop files to upload here')}}</div>-->
<!--          <div class="upload-tip">{{$t('Support extension')}}：.rar .zip .doc .docx .pdf .jpg...</div>-->
<!--        </el-upload>-->
<!--      </div>-->
      <div class="actions">
        <button class="button button-primary" type="button" @click="sendMessageToClient">{{$t('Send')}}</button>
      </div>
    </div>
  </modal>
</template>
<script>
  import Modal from '../../../../components/Modal';
  import {sendMessageToClient} from '@/api/admin-order';

  export default {
    name: 'ContactClientsModal',
    props: {
      orderID: String
    },
    data() {
      return {
        isShow: false,
        content: ''
      };
    },
    components: {Modal},
    methods: {
      show() {
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      },
      sendMessageToClient() {
        const loading = this.$loading({text: 'Loading'});
        sendMessageToClient({message: this.content}, this.orderID).then(res => {
          this.hide();
          loading.close();
        }).catch(() => {
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .contact-clients{
    padding:30px 30px 40px;text-align:left;
    .ipt{
      width:100%;height:60px;padding:0 20px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .actions{margin-top:40px;text-align:center;}
    /deep/ .el-textarea .el-textarea__inner{height:160px;padding:20px;}
    .m-t{margin-top:20px;}
  }
  .order-info{
    padding:4px 20px 20px;background-color:#f4f4f4;border-radius:4px;
    .item{float:left;width:50%;margin-top:16px;padding-right:10px;}
  }
  .upload-wrap{
    /deep/ .el-upload{width:100%;vertical-align:top;}
    /deep/ .el-upload-dragger{
      width:100%;height:263px;border:1px dashed #979797;border-radius:0;
      .ico{width:80px;height:80px;margin:30px auto 0;}
      .upload-txt{margin-top:24px;color:#ff6f61;font-size:24px;}
      .upload-tip{margin-top:16px;color:#999;}
      &:hover{border-color:#ff6f61;}
    }
  }
</style>
