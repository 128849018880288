<template>
  <div class="change-group-inner">
    <div class="cl">
      <p class="fl m-r">{{$t('Order number')}}：001</p>
      <p class="fl">{{$t('ProductNumber')}}：123</p>
    </div>
    <div class="title">{{$t('Please select the passengers who need to be transferred')}}</div>
    <div class="table border traveler-list">
      <table>
        <thead>
        <tr>
          <th class="checkbox"><img src="../../../../assets/images/checkbox.png"/></th>
          <th>{{$t('Appellation')}}</th>
          <th>{{$t('English name')}}</th>
          <th>{{$t('Certificate Number')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="i in 6" :key="i">
          <td class="checkbox"><img src="../../../../assets/images/checkbox.png"/></td>
          <td>Mr.</td>
          <td>Chan/Ka Wai</td>
          <td>H7286437823</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="actions">
      <button class="button button-primary" type="button" @click="onSubmit">{{$t('OK')}}</button>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ChangeGroupByIndividual',
    data() {
      return {};
    },
    created() {
    },
    methods: {
      onSubmit() {
        this.$router.push({name: 'AdminChangeGroup'});
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../../assets/style/table";
  .change-group-inner{
    padding:30px 0 40px;text-align:left;
    .title{
      position:relative;margin-top:24px;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
    .m-r{margin-right:24px;}
  }
  .table.border.traveler-list{margin-top:16px;}
</style>
