<template>
  <Modal :title="$t(orderNotice?'ViewGroupNotice':'Send a group notice')"
         :is-show="isShow"
         v-if="isShow"
         @close-modal="hide">
    <div class="send-reminder">
      <div class="fl-group">
        <!--確定成團-->
        <div class="title">{{$t('DetermineGroup')}}</div>
        <p>{{$t('Hello',[appellationName])}}</p>
        <!--您訂購的旅遊產品：已確認成-->
        <p>{{$t('Travel products you ordered: confirmed to be a group')}}</p>
      </div>
      <div class="fl-group">
        <div class="title">{{$t('Travel Group Information')}}</div>
        <!--訂單編號-->
        <div>{{$t('Order number')}}：{{orderInfo.orderNo}}</div>
        <!--產品編號-->
        <div class="m-t">{{$t('ProductNumber')}}：{{orderInfo.productNo}}</div>
        <!--產品名稱-->
        <div class="m-t">{{$t('ProductName')}}：{{orderInfo.productTitle}}</div>
        <!--行程路線-->
        <div class="m-t">{{$t('Itinerary route')}}：{{orderInfo.lineName}} - {{orderInfo.lineTitle}}</div>
        <!--旅行團出發日-->
        <div class="m-t">{{$t('TourDepartureDay')}}：{{orderInfo.priceDate}}</div>
        <!--人數-->
        <div class="m-t">{{$t('Number of people')}}：{{passengerInfo.length}}</div>
        <!--房間數目-->
        <div v-if="isDay === 0" class="m-t">{{$t('NumberOfRooms')}}：{{orderInfo.roomsNumber}}</div>
      </div>

      <!--未发送-->
      <template v-if="orderInfo.orderStatus==='NOT_GROUP'">
        <div class="fl-group">
          <div class="title">{{$t('Messages to Client')}}</div>
          <!--訂單編號-->
          <el-input type="textarea"
                    placeholder="Messages to Client"
                    rows="5"
                    v-model="message"
                    maxlength="1000"
                    show-word-limit>
          </el-input>
        </div>
        <div class="fl-group">
          <div class="title title-group">
            <checkbox v-model="isInformation"/>&nbsp;
            <div @click="isInformation=!isInformation">{{$t('Introduction to the group')}}（{{$t('Optional')}}）</div>
          </div>
          <table class="table" v-if="isInformation">
            <!--時間-->
            <tr>
              <td class="label">{{$t('Time')}}：</td>
              <td>
                <el-date-picker class="select"
                                v-model="date"
                                type="datetime"
                                size="small"
                                default-time="08:00:00"
                                :picker-options="pickerOptions"
                                value-format="yyyy-MM-dd HH:mm"
                                format="yyyy-MM-dd HH:mm"
                                :placeholder="$t('PleaseChoose')">
                </el-date-picker>
              </td>
            </tr>
            <!--地點-->
            <tr>
              <td class="label">{{$t('Location')}}：</td>
              <td>
                <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                       v-model.trim="location"/>
              </td>
            </tr>
            <!--備註-->
            <tr>
              <td class="label">{{$t('Remarks')}}：</td>
              <td>
                <el-input type="textarea"
                          rows="5"
                          placeholder=""
                          v-model="memo"
                          maxlength="200"
                          show-word-limit>
                </el-input>
              </td>
            </tr>
          </table>
        </div>
        <div class="actions">
          <!--發出成團通知-->
          <button class="button button-primary m-r" type="button" @click="onSubmit">
            {{$t('Send a group notice')}}
          </button>
          <button class="button" type="button" @click="isShow=false">{{$t('Cancel')}}</button>
        </div>
      </template>
      <!--已发送-->
      <template v-else-if="orderNotice.message !== null">
        <div class="fl-group">
          <div class="title">{{$t('Messages to Client')}}</div>
          <!--訂單編號-->
          <span style="white-space: pre-line">{{orderNotice.message}}</span>
        </div>
        <div class="fl-group" v-if="orderNotice.isInformation==='Y'">
          <!--團前簡介會資訊-->
          <div class="title">{{$t('Introduction to the group')}}</div>
          <!--日期-->
          <div>{{$t('Date')}}：{{orderNotice.currentDate}}</div>
          <!--時間-->
          <div class="m-t">{{$t('Time')}}：{{orderNotice.currentTime}}</div>
          <!--地點-->
          <div class="m-t">{{$t('Location')}}：{{orderNotice.location}}</div>
          <!--備註-->
          <div class="m-t">{{$t('Remarks')}}：{{orderNotice.memo}}</div>
        </div>
      </template>
    </div>
  </Modal>
</template>
<script>
  import Checkbox from '../../../../components/Checkbox';
  import {addGroupSuccessForOrderByOrderId} from '../../../../api/admin-order';

  export default {
    name: 'SendReminderModal',
    props: {orderId: String, orderInfo: Object, passengerInfo: Array, contactInformation: Object, orderNotice: Object, isDay: Number},
    data() {
      return {
        isShow: false,
        isInformation: false,
        date: '',
        location: '',
        memo: '',
        message: '',
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now();
          }
        }
      };
    },
    computed: {
      appellationName() {
        if (this.contactInformation) {
          let appellation = '';
          const isCalled = this.contactInformation.isCalled;
          if (isCalled) {
            appellation = isCalled.substring(0, 1) + isCalled.substring(1).toLocaleLowerCase() + '.';
          }
          return `${appellation} ${this.contactInformation.englishSurname}`;
        }
        return '';
      }
    },
    components: {Checkbox},
    methods: {
      show() {
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      },
      onSubmit() {
        let data = {isInformation: 'N', currentDate: '', currentTime: '', location: '', memo: '', message: null};
        if (this.isInformation) {
          if (!this.date) {
            this.$message.warning(this.$t('Please choose', [this.$t('Time')]));
            return false;
          }
          if (!this.location) {
            this.$message.warning(this.$t('Please fill out', [this.$t('Location')]));
            return false;
          }
          data = {
            isInformation: 'Y',
            currentDate: this.date.split(' ')[0],
            currentTime: this.date.split(' ')[1].substring(0, 5),
            location: this.location,
            memo: this.memo,
            message: this.message
          };
        }

        if (this.message.length !== 0) {
          data.message = this.message;
        }

        const loading = this.$loading({text: 'Loading'});
        addGroupSuccessForOrderByOrderId(this.orderId, data).then(res => {
          loading.close();
          this.$message.success('已發送');
          this.isShow = false;
          this.$emit('reload');
        }).catch((e) => {
          loading.close();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .send-reminder{
    padding:0 30px 40px;text-align:left;
    .actions{margin-top:30px;text-align:center;}
  }
  .fl-group{
    .title{
      position:relative;margin:30px 0 24px;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
      span{color:#140d08;font-size:14px;}
    }
    .title-group{
      padding-left:0;
      &:after{display:none;}
      > div{display:inline-block;vertical-align:middle;}
    }
    .m-t{margin-top:8px;}
  }
  .table{
    width:100%;font-size:16px;
    td{padding-bottom:24px;}
    .label{width:48px;line-height:32px;white-space:nowrap;}
    .ipt{
      width:260px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .select{
      width:260px;vertical-align:top;
      /*/deep/ .el-input__inner{
        padding-left:10px;color:#321908;font-size:14px;
      }*/
    }
    /deep/ .el-textarea__inner{height:160px;padding:10px;}
  }
</style>
