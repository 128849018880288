<template>
  <Modal :title="$t('Confirm order')" :is-show="isShow" v-if="isShow" @close-modal="hide">
    <div class="send-reminder">
      <div class="tips" v-html="$t('tipsTxt')"></div>
      <div class="tips" v-html="$t('tipsTxt2')"></div>
      <div class="fl-group">
        <!--報名收據  ＊香港註冊旅行社發出的收據須加蓋TIC電子印花）-->
        <div class="title">
          {{$t('RegistrationReceipt')}}
          <span>（＊{{$t('RegistrationReceiptTips')}}）</span>
        </div>
      </div>
      <div class="upload-wrap">
        <el-upload drag
                   action="/v2/admin/common/file"
                   accept=".pdf, .jpg, .jpeg, .png"
                   multiple
                   :file-list="fileList"
                   :limit="1"
                   :on-exceed="handleExceed"
                   :on-remove="handleRemove"
                   :on-success="handleFileSuccess"
                   ref="uploader">
          <div class="ico">
            <img class="img" src="../../../../assets/images/icon/upload-red-80.png" alt=""/>
          </div>
          <div class="upload-txt">{{$t('Click or drag and drop files to upload here')}}</div>
          <div class="upload-tip">{{$t('Support extension')}}：.pdf .jpg .jpeg .png</div>
        </el-upload>
      </div>
      <div class="actions">
        <button class="button button-primary m-r" type="button" @click="onSubmit">{{$t('Confirm order')}}</button>
        <button class="button" type="button" @click="isShow=false">{{$t('Cancel')}}</button>
      </div>
    </div>
  </Modal>
</template>
<script>
  import {addSignUpSuccessForOrderByOrderId} from '../../../../api/admin-order';

  export default {
    name: 'SendReceiptModal',
    i18n: {
      messages: {
        'zh-cn': {
          tipsTxt: '*确认报名有效，须同时发出旅行社抬头的收据',
          tipsTxt2: '*收据可于稍后补回'
        },
        'zh-hk': {
          tipsTxt: '*確認報名有效，須同時發出旅行社抬頭的收據',
          tipsTxt2: '*收據可於稍後補回'
        },
        'en': {
          tipsTxt: '*確認報名有效，須同時發出旅行社抬頭的收據',
          tipsTxt2: '*收據可於稍後補回'
        }
      }
    },
    props: {orderId: String},
    data() {
      return {
        isShow: false,
        fileList: [],
        fileUrl: '',
        fileName: ''
      };
    },
    methods: {
      show() {
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      },
      handleExceed(files, fileList) {
        this.$message.warning('最多只能上傳1個檔案');
      },
      handleRemove(file) {
        this.fileUrl = '';
        this.fileName = '';
        this.$refs.uploader.clearFiles();
      },
      handleFileSuccess(res, file, fileList) {
        this.fileUrl = res.value.url;
        this.fileName = res.value.fileName;
      },
      onSubmit() {
        if (!this.fileUrl) {
          // ##Bypass file check for late recipe upload
          alert('請記得補回收據！');
          // this.$message.warning('請上載收據！');
          // return false;
        }

        const data = {fileName: this.fileName, fileUrl: this.fileUrl};
        const loading = this.$loading({text: 'Loading'});
        addSignUpSuccessForOrderByOrderId(this.orderId, data).then(res => {
          loading.close();
          this.$message.success('已發送');
          this.isShow = false;
          this.$emit('reload');
        }).catch((e) => {
          loading.close();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .send-reminder{
    padding:0 30px 40px;text-align:left;
    .actions{margin-top:30px;text-align:center;}
  }
  .fl-group{
    .title{
      position:relative;margin:30px 0 24px;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
      span{color:#140d08;font-size:14px;}
    }
    .m-t{margin-top:16px;}
  }
  .upload-wrap{
    /deep/ .el-upload{width:100%;vertical-align:top;}
    /deep/ .el-upload-dragger{
      width:100%;height:263px;border:1px dashed #979797;border-radius:0;
      .ico{width:80px;height:80px;margin:30px auto 0;}
      .upload-txt{margin-top:24px;color:#ff6f61;font-size:24px;}
      .upload-tip{margin-top:16px;color:#999;}
      &:hover{border-color:#ff6f61;}
    }
  }
  .tips{margin-top:30px;color:#ff6f61;}
</style>
