<template>
  <modal id="modifyPassenger" :title="$t('Traveler information')" width="900px" :is-show="isShow" @close-modal="hide" v-if="isShow">
    <div class="passenger-list">
      <add-order-passenger-info-item v-for="(item,index) of $v.passengerList.$each.$iter"
                                     :appellation-options="appellationOptions"
                                     :country-options="countryOptions"
                                     :area-code-options="areaCodeOptions"
                                     :idcard-type-options="certificateTypeOptions"
                                     :birthday-options="birthdayOptions"
                                     :effective-date-options="effectiveDateOptions"
                                     :passenger-list.sync="passengerList"
                                     :datas="item"
                                     :item-index="Number(index)"
                                     :key="index"/>
      <div class="actions">
        <!--保存修改-->
        <button class="button button-primary" @click="onSubmit">{{$t('Save Changes')}}</button>
      </div>
    </div>
  </modal>
</template>
<script>
  import {required, requiredIf} from 'vuelidate/lib/validators';
  import Modal from '../../../../components/Modal';
  import AddOrderPassengerInfoItem from './AddOrderPassengerInfoItem';
  import {scrollToY} from '../../../../utils';
  import {saveOrderTravelerInfo} from '../../../../api/admin-order';

  export default {
    name: 'ModifyPassengerInformationModal',
    props: {
      passengers: Array,
      appellationOptions: Array,
      areaCodeOptions: Array,
      countryOptions: Array,
      certificateTypeOptions: Array
    },
    data() {
      return {
        isShow: false,
        orderId: '',
        passengerList: this.passengers.map(item => ({...item})),
        birthdayOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        effectiveDateOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now();
          }
        }
      };
    },
    validations: {
      passengerList: {
        required,
        $each: {
          isCalled: {required},
          englishSurname: {required},
          englishName: {required},
          chineseSurname: {},
          chineseName: {},
          genderType: {required},
          paperworkType: {required},
          otherPaperwork: {
            required: requiredIf((datas) => {
              return datas.paperworkType === 'qt';
            })
          },
          issuingCountry: {required},
          idNumber: {required},
          effectiveDate: {
            required: requiredIf((datas) => {
              return datas.paperworkType !== 'sfz';
            })
          },
          birthday: {required},
          phoneType: {},
          userPhone: {},
          travelerType: {}
        }
      }
    },
    components: {AddOrderPassengerInfoItem, Modal},
    created() {
      this.orderId = this.$route.query.id;
    },
    methods: {
      show() {
        this.passengerList = this.passengers.map(item => ({...item}));
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      },
      onSubmit() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$message.warning(this.$t('Please complete the information before submitting'));
          this.$nextTick(() => {
            const $error = document.querySelector('#modifyPassenger').querySelector('.is-error');
            if ($error) {
              scrollToY(document.querySelector('#modifyPassenger').querySelector('.modal-content'), $error.offsetTop - 80);
            }
          });
          return false;
        }

        const loading = this.$loading({text: 'Loading'});
        saveOrderTravelerInfo(this.orderId, this.passengerList).then(res => {
          this.$emit('update:passengers', this.passengerList.map(item => ({...item})));
          this.hide();
          loading.close();
          this.$message.success('修改成功！');
        }).catch(() => {
          loading.close();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .passenger-list{
    padding:30px;text-align:left;
    .actions{margin-top:30px;text-align:center;}
  }
</style>
